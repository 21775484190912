import React from "react";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
  IInitialState,
  AccionDetalle,
  TipoAccionDetalle,
} from "../Shared/data/FormReducer";
import { GuardarUsuario } from "../Services/Form";

interface ISteps {
  state: IInitialState;
  dispatch: React.Dispatch<AccionDetalle>;
}

const Steps: React.FC<ISteps> = (props) => {
  const theme = useTheme();

  const handleNext = () => {
    let errores: boolean = false;

    switch (props.state.nForm) {
      case 1:
        if (props.state.estatura === "0") {
          props.dispatch({
            type: TipoAccionDetalle.CHANGE_VALESTATURA,
            payload: true,
          });
          errores = true;
        }
        break;

      case 2:
        if (props.state.peso === 0) {
          props.dispatch({
            type: TipoAccionDetalle.CHANGE_VALPESO,
            payload: true,
          });
          errores = true;
        }
        if (props.state.porcentajeGrasa === 0) {
          props.dispatch({
            type: TipoAccionDetalle.CHANGE_VALPGRASA,
            payload: true,
          });
          errores = true;
        }
        break;

      case 3:
        if (props.state.tipoActividad === "") {
          props.dispatch({
            type: TipoAccionDetalle.CHANGE_VALTACTIVIDAD,
            payload: true,
          });
          errores = true;
        }

        if (props.state.objetivo === "") {
          props.dispatch({
            type: TipoAccionDetalle.CHANGE_VALOBJETIVO,
            payload: true,
          });
          errores = true;
        }
        break;

      default:
        break;
    }

    if (!errores) {
      props.dispatch({
        type: TipoAccionDetalle.CHANGE_NFORM,
        payload: props.state.nForm + 1,
      });
      if (props.state.nForm === 3) pasarAFinal();
    }
    if (errores) {
      errores = false;
      alert("Completa los espacios en blanco.");
    }
  };

  const handleBack = () => {
    props.dispatch({
      type: TipoAccionDetalle.CHANGE_NFORM,
      payload: props.state.nForm - 1,
    });
  };

  const pasarAFinal = () => {
    const date = new Date(Date.parse(props.state.edad));

    const FFormateada = `${date.getFullYear()}-${
      date.getMonth() + 1 < 10 ? "0" : ""
    }${date.getMonth() + 1}-${date.getDate() < 10 ? "0" : "" + date.getDate()}`;

    GuardarUsuario({
      Nombres: props.state.nombre,
      Correo: props.state.correo,
      Estatura: props.state.estatura,
      peso: props.state.peso,
      FechaNacimiento: FFormateada,
      PorcentajeGrasa: props.state.porcentajeGrasa,
      TipoActividad: props.state.tipoActividad,
      Objetivo: props.state.objetivo,
      UnidadPeso: props.state.unidadPeso,
      UnidadEstatura: props.state.unidadEstatura,
      Sexo: props.state.sexo,
    })
      .then((response) => {
        if (response.status === 200) {
          props.dispatch({
            type: TipoAccionDetalle.CHANGE_CARBOHIDRATOS,
            payload: response.data.data.carbohidratos,
          });
          props.dispatch({
            type: TipoAccionDetalle.CHANGE_PROTEINAS,
            payload: response.data.data.proteinas,
          });
          props.dispatch({
            type: TipoAccionDetalle.CHANGE_GRASAS,
            payload: response.data.data.grasas,
          });
          props.dispatch({
            type: TipoAccionDetalle.CHANGE_NOMBRE,
            payload: response.data.data.nombres,
          });
        } else {
          console.log("Ocurrió un problema, contáctese con nuestros asesores.");
          alert("Ocurrió un problema, contáctese con nuestros asesores.");
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  return (
    <div className="d-flex justify-content-center mt-4">
      <MobileStepper
        variant="progress"
        steps={5}
        position="static"
        activeStep={props.state.nForm}
        sx={{
          maxWidth: 800,
          flexGrow: 1,
          ".MuiLinearProgress-bar": { backgroundColor: "#ff8b01" },
          ".MuiMobileStepper-progress": {
            backgroundColor: "grey",
            height: "10px",
          },
          // backgroundColor: "#222529",
        }}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={props.state.nForm === 5}
            sx={{ color: "#fba30a" }}
          >
            {/* {props.state.nForm !== 4 ? "Siguiente" : "Finalizar"} */}
            {/* {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )} */}
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={handleBack}
            disabled={props.state.nForm === 0}
            sx={{ color: "#fba30a" }}
          >
            {/* {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Atras */}
          </Button>
        }
      />
    </div>
  );
};

export default Steps;
