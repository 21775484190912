import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Radio, TextField } from "@mui/material";

export const Tr4inerOutlinedButton = styled(Button)(() => ({
  color: "#000",
  fontWeight: "bold",
  borderColor: "#adb5bd",
  fontSize: "12px",
  "&:hover": {
    borderColor: "#adb5bd",
    backgroundColor: "#adb5bd",
    color: "#fff",
  },
}));

export const Tr4inerContainedButton = styled(Button)(() => ({
  color: "#fff",
  fontWeight: "bold",
  backgroundColor: "#ff8b01",
  borderColor: "#ff8b01",
  boxShadow: "0px 0px 0px 0px",
  fontSize: "12px",
  "&:hover": {
    borderColor: "#ff8b01",
    backgroundColor: "#ff8b01",
    opacity: "0.5",
  },
}));

export const Tr4inerTextField = styled(TextField)(() => ({
  width: "300px",
  "& .MuiInputLabel-root": {
    color: "#000",
  },
  "& .MuiInputBase-input": {
    color: "#000", // Cambia el color del texto de entrada
  },
  "& label.Mui-focused": {
    color: "#000", // Cambia el color del label cuando está seleccionado
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "#000", // Estilo de línea sin seleccionar
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#000", // Estilo de línea seleccionado
  },
  "& .MuiInput-underline:hover:before": {
    borderBottomColor: "#000", // Estilo de línea durante el hover
  },
  "& .MuiFormHelperText-root": {
    color: "#000", // Cambia el color del helperText
  },
}));

export const Tr4inerRadio = styled(Radio)(() => ({
  color: "#000", // Cambia el color del círculo del radio
  "&.Mui-checked": {
    color: "#000", // Cambia el color del círculo del radio cuando está seleccionado
  },
}));
