import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import logo from "../Shared/Images/logo_tr4iner.png";

const Header = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar
          sx={{
            backgroundColor: "#000",
            display: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={logo}
            width={150}
            style={{ cursor: "pointer" }}
            onClick={() => window.location.reload()}
            alt='logo'
          />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
