import {
  AccionDetalle,
  IInitialState,
  TipoAccionDetalle,
} from "../Shared/data/FormReducer";
import { Tr4inerContainedButton, Tr4inerTextField } from "./Material";
import { sitekeyrecatpchaGoogleV2 } from "../Config/baseUrl";
import ReCAPTCHA from "react-google-recaptcha";
import toast from "react-hot-toast";
import validator from "validator";
import ToastDisplay from "../Shared/Utils/ToastDisplay";
import { handlerButtonToTop } from "../Shared/Utils/scroll";

interface IForm5 {
  state: IInitialState;
  dispatch: React.Dispatch<AccionDetalle>;
}

const Form5: React.FC<IForm5> = (props) => {
  const handleNext = () => {
    let error1 = false;
    let error2 = false;
    let error3 = false;

    if (props.state.nombre === "") {
      props.dispatch({
        type: TipoAccionDetalle.CHANGE_VALNOMBRE,
        payload: true,
      });
      error1 = true;
    }

    if (!validator.isEmail(props.state.correo)) {
      props.dispatch({
        type: TipoAccionDetalle.CHANGE_VALCORREO,
        payload: true,
      });
      error2 = true;
    }
    if (!props.state.valCaptcha) {
      error3 = true;
    }

    if (!error1 && !error2 && !error3) {
      props.dispatch({
        type: TipoAccionDetalle.CHANGE_NFORM,
        payload: props.state.nForm + 1,
      });
      handlerButtonToTop();
    }
    if (error1 || error2 || error3) {
      if (error1 && error2) {
        toast.error(
          <ToastDisplay
            title={``}
            message={"Completa tu nombre y correo antes de continuar."}
          />
        );
      } else if (error1 && !error2 && !error3) {
        toast.error(
          <ToastDisplay
            title={``}
            message={"Completa tu nombre antes de continuar."}
          />
        );
      } else if (!error1 && error2 && !error3) {
        toast.error(
          <ToastDisplay
            title={``}
            message={"Completa tu correo antes de continuar."}
          />
        );
      } else if (!error1 && !error2 && error3) {
        toast.error(
          <ToastDisplay
            title={``}
            message={"Completa la verificación antes de continuar."}
          />
        );
      }
      error1 = false;
      error2 = false;
      error3 = false;
    }
  };

  const onchangeCaptcha = (currentValue: any) => {
    if (currentValue) {
      props.dispatch({
        type: TipoAccionDetalle.CHANGE_VALCAPTCHA,
        payload: true,
      });
    }
  };

  return (
    <div className="container form-macro">
      <div className="text-center mb-5">
        <h2 className=" text-center title mt-4">Conoce tus macros aquí</h2>
      </div>
      <section id="form1">
        <div className="row">
          <div className="d-flex justify-content-center">
            <Tr4inerTextField
              error={props.state.valNombre}
              id="nombre"
              label="Nombre"
              type="text"
              variant="standard"
              value={props.state.nombre}
              onChange={(e) => {
                props.dispatch({
                  type: TipoAccionDetalle.CHANGE_NOMBRE,
                  payload: e.target.value,
                });
                props.dispatch({
                  type: TipoAccionDetalle.CHANGE_VALNOMBRE,
                  payload: false,
                });
              }}
            />
          </div>
        </div>
        <br></br>
        <div className="row mb-4">
          <div className="d-flex justify-content-center">
            <Tr4inerTextField
              error={props.state.valCorreo}
              id="correo"
              label="Correo"
              type="email"
              variant="standard"
              value={props.state.correo}
              onChange={(e) => {
                props.dispatch({
                  type: TipoAccionDetalle.CHANGE_CORREO,
                  payload: e.target.value,
                });
                props.dispatch({
                  type: TipoAccionDetalle.CHANGE_VALCORREO,
                  payload: false,
                });
              }}
            />
          </div>
        </div>
        <ReCAPTCHA
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
          hl="es"
          sitekey={sitekeyrecatpchaGoogleV2}
          onChange={onchangeCaptcha}
          badge="bottomright"
        />
        <br />
        <div className="d-flex justify-content-center my-2">
          <Tr4inerContainedButton
            variant="contained"
            size="large"
            sx={{
              textTransform: "inherit",
            }}
            onClick={handleNext}
          >
            ¡Quiero mis macros!
          </Tr4inerContainedButton>
        </div>
      </section>
    </div>
  );
};

export default Form5;
