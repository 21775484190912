import { AccionDetalle, IInitialState, TipoAccionDetalle } from "../Shared/data/FormReducer";
import { TipoTest, usuarioTipoURL } from "../Shared/Utils/helper-tipo-usuario";
import Form01 from "./Form01";
import Form02 from "./Form02";
import Form03 from "./Form03";
import Form04 from "./Form04";
import Resultados from "./Resultados";
import { useLocation } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useQuery } from "../Shared/Utils/hooks/path";
import Form06 from "./Form06";

interface IMainForm {
  state: IInitialState;
  dispatch: React.Dispatch<AccionDetalle>;
}
type IUsuario = {
  [key: string]: string;
};

const MainForm: React.FC<IMainForm> = (props) => {
  const { search } = useLocation();
  let query = useQuery();

  const onDecodeParamsUTM = () => {
    const ref = query.get("ref");
    const utm_medium = query.get("utm_medium");
    const utm_source = query.get("utm_source");
    const utm_campaign = query.get("utm_campaign");
    const utm_term = query.get("utm_term");
    const utm_content = query.get("utm_content");
    const landing = query.get("landing");

    props.dispatch({
      type: TipoAccionDetalle.CHANGE_REF,
      payload: ref ?? "",
    });

    props.dispatch({
      type: TipoAccionDetalle.CHANGE_UTM_MEDIUM,
      payload: utm_medium ?? "",
    });

    props.dispatch({
      type: TipoAccionDetalle.CHANGE_UTM_SOURCE,
      payload: utm_source ?? "",
    });

    props.dispatch({
      type: TipoAccionDetalle.CHANGE_UTM_CAMPAIGN,
      payload: utm_campaign ?? "",
    });

    props.dispatch({
      type: TipoAccionDetalle.CHANGE_UTM_TERM,
      payload: utm_term ?? "",
    });

    props.dispatch({
      type: TipoAccionDetalle.CHANGE_UTM_CONTENT,
      payload: utm_content ?? "",
    });

    props.dispatch({
      type: TipoAccionDetalle.CHANGE_LANDING,
      payload: landing ?? "",
    });
  };

  const onDecodeParams = useCallback((params: string) => {
    const replaceFirstCharacter = params.replace("?", "");
    // console.log(replaceFirstCharacter);
    const splitString = replaceFirstCharacter.split("&");
    const formattedQueries = {} as IUsuario;
    let nombreBool: Boolean = false;
    let correoBool: Boolean = false;
    splitString.forEach((query) => {
      const [key, value] = query.split("=");
      Object.assign(formattedQueries, {
        [key]: value,
      });

      var keyParse = key.toLowerCase();

      if (keyParse === "correo") {
        correoBool = true;
        props.dispatch({
          type: TipoAccionDetalle.CHANGE_CORREO,
          payload: value,
        });
      }
      if (keyParse === "nombre") {
        nombreBool = true;
        props.dispatch({
          type: TipoAccionDetalle.CHANGE_NOMBRE,
          payload: decodeURIComponent(value),
        });
      }
      if (keyParse === "pag") {
        props.dispatch({
          type: TipoAccionDetalle.CHANGE_NFORM,
          payload: parseInt(decodeURIComponent(value)),
        });
      }
      if (keyParse === "objetivo") {
        props.dispatch({
          type: TipoAccionDetalle.CHANGE_OBJETIVO,
          payload: decodeURIComponent(value),
        });
      }
      if (keyParse === "edad") {
        var fechaActual = new Date();
        props.dispatch({
          type: TipoAccionDetalle.CHANGE_EDAD,
          payload: `Fri Mar 01 ${fechaActual.getFullYear() - parseInt(value)} 10:46:15 GMT-0500`,
        });
      }
    });
    if (correoBool && nombreBool) {
      props.dispatch({
        type: TipoAccionDetalle.CHANGE_NFORM,
        payload: props.state.nForm + 1,
      });
    }
  }, []);

  useEffect(() => {
    if (search.trim()) {
      onDecodeParams(search);
    }
  }, [onDecodeParams, search]);

  useEffect(() => {
    if (search.trim()) {
      onDecodeParamsUTM();
    }
  }, []);

  useEffect(() => {
    if (usuarioTipoURL() === TipoTest.TEST_VSLH_MACROS || usuarioTipoURL() === TipoTest.TEST_VSLM_MACROS) {
      props.dispatch({
        type: TipoAccionDetalle.CHANGE_SEXO,
        payload: usuarioTipoURL() === TipoTest.TEST_VSLH_MACROS ? true : false,
      });
    }
  }, []);

  return (
    <div>
      <div>
        {props.state.nForm === 0 && <Form01 state={props.state} dispatch={props.dispatch} />}
        {props.state.nForm === 1 && <Form02 state={props.state} dispatch={props.dispatch} />}
        {props.state.nForm === 2 && <Form03 state={props.state} dispatch={props.dispatch} />}
        {props.state.nForm === 3 && <Form04 state={props.state} dispatch={props.dispatch} />}
        {props.state.nForm === 4 && <Resultados state={props.state} />}
      </div>
      <Toaster />
    </div>
  );
};

export default MainForm;
