import React from "react";

function Footer() {
  return (
    <div style={{ margin: "0px 0px 20px 0px" }}>
      <div className="container mt-4 text-center">
        <hr />
        <div className="mb-1 footerText">
          <p className="footer-text m-0">
            Todos los derechos reservados por ®Tr4iner
          </p>
        </div>
        <a
          href="https://tr4iner.com/terminos-y-condiciones"
          target="_blank"
          className="footer-link"
        >
          Términos
        </a>
        &nbsp;&nbsp;&nbsp;
        <a
          href="https://tr4iner.com/politica-de-privacidad"
          target="_blank"
          className="footer-link"
        >
          Privacidad
        </a>
        &nbsp;&nbsp;&nbsp;
        <a
          href="mailto:metodo@tr4iner.com"
          target="_blank"
          className="footer-link"
        >
          Contacto
        </a>
      </div>
    </div>
  );
}

export default Footer;
