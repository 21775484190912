const testMacros = "test-macros.tr4iner.com";
const testMacrosVA = "va-test-macros.tr4iner.com";
const testMacrosVSLH = "test1h-macros.tr4iner.com";
const testMacrosVSLM = "test1m-macros.tr4iner.com";
const local = "localhost";

const hostname = window.location.hostname;

export enum TipoTest {
  TEST_MACROS = "macros",
  TEST_VA_MACROS = "macros-va",
  TEST_VSLH_MACROS = "macros-vsl-h",
  TEST_VSLM_MACROS = "macros-vsl-m",
}

export const usuarioTipoURL = () => {
  if (hostname === testMacros || hostname === local) {
    return TipoTest.TEST_MACROS;
  } else if (hostname === testMacrosVA) {
    return TipoTest.TEST_VA_MACROS;
  } else if (hostname === testMacrosVSLH) {
    return TipoTest.TEST_VSLH_MACROS;
  } else if (hostname === testMacrosVSLM) {
    return TipoTest.TEST_VSLM_MACROS;
  }
  return;
};
