import React from "react";

interface IToastDisplay {
  title: string;
  message: string;
}

const ToastDisplay: React.FC<IToastDisplay> = (props) => {
  console.log(props);
  return (
    <div>
      <h5 className="font-weight-bold">{props.title}</h5>
      <p className="font-weight-normal">{props.message}</p>
    </div>
  );
};

export default ToastDisplay;
