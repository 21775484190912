import { useMemo } from "react";
import { useLocation } from "react-router-dom";

/**
 * Este hook sirve para obtener los parametros "search" de la url.
 * Ejemplo:
 *        www.miembro.tr4iner.com?parametro1=Test&parametro2=Prueba"
 *
 * Se podrá obtener cada parámetro por separado
 */
export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
