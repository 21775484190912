import React, { useEffect } from "react";
import { IInitialState, AccionDetalle, TipoAccionDetalle } from "../Shared/data/FormReducer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import imgHombre from "../Shared/Images/HombrePorcentaje.jpg";
import imgMujer from "../Shared/Images/MujerPorcentaje.jpg";
import { Tr4inerContainedButton, Tr4inerOutlinedButton, Tr4inerRadio, Tr4inerTextField } from "./Material";
import { FormControl, FormControlLabel, InputAdornment, Radio, RadioGroup } from "@mui/material";
import { toast } from "react-hot-toast";
import ToastDisplay from "../Shared/Utils/ToastDisplay";
import { handlerButtonToTop } from "../Shared/Utils/scroll";
import { registrarLeadAccion } from "../Services/Form";

interface IForm2 {
  state: IInitialState;
  dispatch: React.Dispatch<AccionDetalle>;
}

const Form2: React.FC<IForm2> = (props) => {
  const handleItem = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, tipo: string) => {
    props.dispatch({
      type:
        tipo === "peso"
          ? TipoAccionDetalle.CHANGE_PESO
          : tipo === "porcentajeGrasa"
          ? TipoAccionDetalle.CHANGE_PORCENTAJEGRASA
          : TipoAccionDetalle.CHANGE_UNIDADPESO,
      payload: e.target.value,
    });

    props.dispatch({
      type: tipo === "peso" ? TipoAccionDetalle.CHANGE_VALPESO : TipoAccionDetalle.CHANGE_VALPGRASA,
      payload: false,
    });
  };

  const handleNext = () => {
    let error1 = false; // peso
    let error2 = false; // %grasa
    let error3 = false; // unidad de peso

    if (props.state.peso === 0) {
      props.dispatch({
        type: TipoAccionDetalle.CHANGE_VALPESO,
        payload: true,
      });
      error1 = true;
    }
    if (props.state.porcentajeGrasa === 0) {
      props.dispatch({
        type: TipoAccionDetalle.CHANGE_VALPGRASA,
        payload: true,
      });
      error2 = true;
    }
    if (props.state.unidadPeso === "") error3 = false;
    if (!error1 && !error2 && !error3) {
      props.dispatch({
        type: TipoAccionDetalle.CHANGE_NFORM,
        payload: props.state.nForm + 1,
      });
      handlerButtonToTop();
      registrarLeadAccion({
        accion: "siguiente2",
        lead: "testmacros-tr4iner",
        uid: props.state.idSession,
      });
    }
    if (error1 || error2 || error3) {
      if (error1 && error2 && error3) {
        toast.error(<ToastDisplay title={``} message={"Marca la unidad peso y porcentaje de grasa antes de continuar."} />);
      } else if (error1 && !error2 && !error3) {
        toast.error(<ToastDisplay title={``} message={"Completa tu peso antes de continuar."} />);
      } else if (!error1 && error2 && !error3) {
        toast.error(<ToastDisplay title={``} message={"Completa tu porcentaje de grasa antes de continuar."} />);
      } else if (!error1 && !error2 && error3) {
        toast.error(<ToastDisplay title={``} message={"Selecciona unidad de peso."} />);
      } else if (error1 && error2 && !error3) {
        toast.error(<ToastDisplay title={``} message={"Completa tu peso y porcentaje de grasa antes de continuar."} />);
      }
      error1 = false;
      error2 = false;
      error3 = false;
    }
  };

  return (
    <div className="container form-macro">
      <h5 className="title text-center">¿Cuánto pesas?</h5>
      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-12 col-md-6  d-flex justify-content-center">
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              value={props.state.unidadPeso}
              onChange={(e) => handleItem(e, "uPeso")}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 15,
                },
              }}
            >
              <FormControlLabel value="Kg" control={<Tr4inerRadio />} label="Kilogramos" />
              <FormControlLabel value="Lb" control={<Tr4inerRadio />} label="Libras" />
            </RadioGroup>
          </FormControl>
        </div>
        {(props.state.unidadPeso === "Kg" || props.state.unidadPeso === "Lb") && (
          <div className="col-12 col-md-6 mb-3 text-center">
            <Tr4inerTextField
              error={props.state.valPeso}
              id="peso"
              type="number"
              label="Peso"
              variant="standard"
              value={props.state.peso === 0 ? "" : props.state.peso}
              onChange={(e) => handleItem(e, "peso")}
              fullWidth
              inputProps={{ min: 0 }}
            />
          </div>
        )}
      </div>
      <br />
      <div className="mx-2">
        <h5 className="title text-center">¿Cuál es tu porcentaje de grasa?</h5>
        <div className="container">
          <p className="text-center m-0">Puedes conocer tu % de grasa en algunas farmacias, gimnasios y hospitales.</p>
          <p className="text-center m-0">ó</p>
          <p className="text-center">Puedes guiarte de manera rápida tomando en cuenta esta imagen de referencia:</p>
        </div>
      </div>
      <div className="row text-center">
        <div className="col-12">
          <img src={props.state.sexo ? imgHombre : imgMujer} width="80%" />
        </div>
      </div>
      <div className="row mt-1">
        <div className="d-flex justify-content-center">
          <Tr4inerTextField
            error={props.state.valPGrasa}
            id="pGrasa"
            label="Porcentaje de grasa"
            type="number"
            variant="standard"
            value={props.state.porcentajeGrasa === 0 ? "" : props.state.porcentajeGrasa}
            onChange={(e) => handleItem(e, "porcentajeGrasa")}
            inputProps={{ min: 0 }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </div>
      </div>
      <div className="row d-flex mt-5">
        <div className="col-6 d-flex justify-content-end">
          <Tr4inerOutlinedButton
            variant="outlined"
            size="large"
            sx={{
              color: props.state.themeColor === "light" ? "primary-color" : "dark-color",
              textTransform: "uppercase",
              width: "130px",
            }}
            onClick={() => {
              props.dispatch({
                type: TipoAccionDetalle.CHANGE_NFORM,
                payload: props.state.nForm - 1,
              });
              handlerButtonToTop();
            }}
          >
            {`<< Atrás`}
          </Tr4inerOutlinedButton>
        </div>
        <div className="col-6 d-flex justify-content-start">
          <Tr4inerContainedButton
            variant="contained"
            size="large"
            sx={{
              textTransform: "uppercase",
              mr: 3,
            }}
            onClick={handleNext}
          >
            {`Siguiente >>`}
          </Tr4inerContainedButton>
        </div>
      </div>
    </div>
  );
};

export default Form2;
