import React, { useEffect } from "react";
import { IInitialState } from "../Shared/data/FormReducer";
import { Tr4inerContainedButton } from "./Material";
import Loading from "./Loading";
import Macronutrientes from "./Macronutrientes";
import { TipoTest, usuarioTipoURL } from "../Shared/Utils/helper-tipo-usuario";
import { InlineWidget } from "react-calendly";
import ReactPlayer from "react-player";
import { Widget } from "@typeform/embed-react";
import { useMediaQuery } from "@mui/material";

interface IResultados {
  state: IInitialState;
}

const Resultados: React.FC<IResultados> = (props) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  /**
   * Hombre = true,
   * Mujer = false,
   *
   * PG = Pérdida de grasa,
   * FM = Fuera de forma -- DEPRECATED
   * DG = Delgado graso  -- DEPRECATED
   * MM = Masa muscular
   */

  // Test Macros
  const OpenDefaultLink = () => {
    if (edadUsuario() >= 18 && edadUsuario() <= 34) {
      if (props.state.sexo) {
        switch (props.state.objetivo) {
          case "PG":
            window.open("https://metodo.tr4iner.com/sobrepeso18-34-h", "_blank");
            break;
          case "MM":
            window.open("https://metodo.tr4iner.com/muydelgado18-34-h", "_blank");
            break;
        }
      } else {
        switch (props.state.objetivo) {
          case "PG":
            window.open("https://metodo.tr4iner.com/sobrepeso18-34-m", "_blank");
            break;
          case "MM":
            window.open("https://metodo.tr4iner.com/muydelgada18-34-m", "_blank");
            break;
        }
      }
    } else {
      if (props.state.sexo) {
        switch (props.state.objetivo) {
          case "PG":
            window.open("https://metodo.tr4iner.com/sobrepeso35-45-h", "_blank");
            break;
          case "MM":
            window.open("https://metodo.tr4iner.com/muydelgado35-45-h", "_blank");
            break;
        }
      } else {
        switch (props.state.objetivo) {
          case "PG":
            window.open("https://metodo.tr4iner.com/sobrepeso35-45-m", "_blank");
            break;
          case "MM":
            window.open("https://metodo.tr4iner.com/muydelgada35-45-m", "_blank");
            break;
        }
      }
    }
  };

  const ChoosenUrl = () => {
    if (!props.state.sexo) {
      if (props.state.objetivo === "PG") {
        return "https://vimeo.com/989794276";
      } else {
        return "https://vimeo.com/990082525";
      }
    } else {
      if (props.state.objetivo === "PG") {
        return "https://vimeo.com/990082216";
      } else {
        return "https://vimeo.com/990081908";
      }
    }
  };

  // Test Macros Vero
  const OpenDefaultLinkVA = () => {
    if (edadUsuario() >= 18 && edadUsuario() <= 34) {
      if (props.state.sexo) {
        switch (props.state.objetivo) {
          case "PG":
            window.open("https://asesoria.tr4iner.com/h18-34-sobrepeso-va", "_blank");
            break;
          case "MM":
            window.open("https://asesoria.tr4iner.com/h18-34-muy-delgado-va", "_blank");
            break;
        }
      } else {
        switch (props.state.objetivo) {
          case "PG":
            window.open("https://asesoria.tr4iner.com/m18-34-sobrepeso-va", "_blank");
            break;
          case "MM":
            window.open("https://asesoria.tr4iner.com/m18-34-muy-delgada-va", "_blank");
            break;
        }
      }
    } else {
      if (props.state.sexo) {
        switch (props.state.objetivo) {
          case "PG":
            window.open("https://asesoria.tr4iner.com/h35-45-sobrepeso-va", "_blank");
            break;
          case "MM":
            window.open("https://asesoria.tr4iner.com/h35-45-muy-delgado-va", "_blank");
            break;
        }
      } else {
        switch (props.state.objetivo) {
          case "PG":
            window.open("https://asesoria.tr4iner.com/m35-45-sobrepeso-va", "_blank");
            break;
          case "MM":
            window.open("https://asesoria.tr4iner.com/m35-45-muy-delgada-va", "_blank");
            break;
        }
      }
    }
  };

  // Test VSL Mujer
  const OpenDefaultLinkVSLMujer = () => {
    if (edadUsuario() >= 18 && edadUsuario() <= 34) {
      if (props.state.sexo) {
        switch (props.state.objetivo) {
          case "PG":
            window.open("https://metodo.tr4iner.com/sobrepeso18-34-h-vsl1m", "_blank");
            break;
          case "MM":
            window.open("https://metodo.tr4iner.com/muydelgado18-34-h-vsl1m", "_blank");
            break;
        }
      } else {
        switch (props.state.objetivo) {
          case "PG":
            window.open("https://metodo.tr4iner.com/sobrepeso35-45-h-vsl1m", "_blank");
            break;
          case "MM":
            window.open("https://metodo.tr4iner.com/muydelgado35-45-h-vsl1m", "_blank");
            break;
        }
      }
    } else {
      if (props.state.sexo) {
        switch (props.state.objetivo) {
          case "PG":
            window.open("https://metodo.tr4iner.com/sobrepeso18-34-m-vsl1m", "_blank");
            break;
          case "MM":
            window.open("https://metodo.tr4iner.com/muydelgada18-34-m-vsl1m", "_blank");
            break;
        }
      } else {
        switch (props.state.objetivo) {
          case "PG":
            window.open("https://metodo.tr4iner.com/sobrepeso35-45-m-vsl1m", "_blank");
            break;
          case "MM":
            window.open("https://metodo.tr4iner.com/muydelgada35-45-m-vsl1m", "_blank");
            break;
        }
      }
    }
  };

  // Test VSL Hombre
  const OpenDefaultLinkVSLHombre = () => {
    if (edadUsuario() >= 18 && edadUsuario() <= 34) {
      if (props.state.sexo) {
        switch (props.state.objetivo) {
          case "PG":
            window.open("https://metodo.tr4iner.com/sobrepeso18-34-h-vsl1h", "_blank");
            break;
          case "MM":
            window.open("https://metodo.tr4iner.com/muydelgado18-34-h-vsl1h", "_blank");
            break;
        }
      } else {
        switch (props.state.objetivo) {
          case "PG":
            window.open("https://metodo.tr4iner.com/sobrepeso35-45-h-vsl1h", "_blank");
            break;
          case "MM":
            window.open("https://metodo.tr4iner.com/muydelgado35-45-h-vsl1h", "_blank");
            break;
        }
      }
    } else {
      if (props.state.sexo) {
        switch (props.state.objetivo) {
          case "PG":
            window.open("https://metodo.tr4iner.com/sobrepeso18-34-m-vsl1h", "_blank");
            break;
          case "MM":
            window.open("https://metodo.tr4iner.com/muydelgada18-34-m-vsl1h", "_blank");
            break;
        }
      } else {
        switch (props.state.objetivo) {
          case "PG":
            window.open("https://metodo.tr4iner.com/sobrepeso35-45-m-vsl1h", "_blank");
            break;
          case "MM":
            window.open("https://metodo.tr4iner.com/muydelgada35-45-m-vsl1h", "_blank");
            break;
        }
      }
    }
  };

  console.log(props.state);

  const edadUsuario = () => {
    let dateNow = new Date();
    let date = new Date(Date.parse(props.state.edad));

    let diferencia = dateNow.getFullYear() - date.getFullYear();
    return diferencia;
  };

  useEffect(() => {
    // Verifica si el script ya existe en la página
    const existingScript = document.getElementById("typeform-embed");
    if (!existingScript) {
      const script = document.createElement("script");
      script.id = "typeform-embed";
      script.src = "//embed.typeform.com/next/embed.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  return (
    <div className="" style={{ width: isMobile ? "100%" : "50%", margin: "auto" }}>
      {/* {isMobile ? (
        <></>
        ) : (
          <h3 className="text-center mb-2 d-flex justify-content-center flex-column">
          <span className="mb-2" style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          {props.state.nombre}
          </span>
          <span> Tus macronutrientes son:</span>
          </h3>
          )} */}

      <div
        style={{
          background: "linear-gradient(90deg, rgba(255, 186, 0, 0.7) 0%, rgba(255, 186, 0, 0.3) 100%)",
          borderRadius: isMobile ? "" : "1rem",
          marginTop: isMobile ? "" : "1.5rem",
        }}
        className="p-4"
      >
        <h4 className="text-center mb-2">
          <span style={{ fontWeight: "bold" }}>¡Felicidades!</span>
        </h4>
        <section className="row py-2" style={{ gap: "1rem" }}>
          <div className="col d-flex align-items-center justify-content-center text-center">
            <p style={{ margin: "0", width: "350px" }}>
              Según tus respuestas, tu cuerpo necesita para tu objetivo de{" "}
              <span style={{ fontWeight: "bold" }}>{props.state.objetivo === "PG" ? "Perder Grasa" : "Masa Muscular"}</span>
            </p>
          </div>
          <div className="d-flex flex-column align-items-center col" style={{ gap: ".5rem" }}>
            <div className="text-center" style={{ fontWeight: "bold", color: "#fd3d48" }}>
              Proteínas: {props.state.proteinas} gramos
            </div>
            <div
              className="text-center"
              style={{
                fontWeight: "bold",
                color: props.state.themeColor === "light" ? "secundary-color" : "primary-color",
              }}
            >
              Carbohidratos: {props.state.carbohidratos} gramos
            </div>
            <div className="text-center" style={{ fontWeight: "bold", color: "#ff8e01" }}>
              Grasas: {props.state.grasas} gramos
            </div>
            <p style={{ margin: "0", width: "350px", textAlign: "center" }}>
              (Equivale a: <b>{props.state.calorias}</b> calorías)
            </p>
          </div>
          {/* <div className="col d-flex align-items-center justify-content-center text-center">
            <p style={{ margin: "0", width: "350px" }}>
              Calorías: {props.state.calorias}
            </p>
          </div> */}
        </section>
      </div>
      <div className="align-items-center justify-content-center mb-2">
        <div className="text-wrap mx-3">
          <div className="">
            {props.state.limitBudget !== "2" && (
              <>
                {isMobile ? (
                  <p className="text-center m-0 my-3">
                    <span style={{ fontWeight: "light", fontSize: "1rem" }}>
                      Seguro te preguntarás <span style={{ fontWeight: "bold", fontSize: "1.3rem" }}>¿Cómo comer</span>{" "}
                    </span>
                    <br />
                    <span style={{ fontWeight: "bold", fontSize: "1.3rem" }}>según tus macronutrientes?</span>
                  </p>
                ) : (
                  <p className="text-center m-0 my-3">
                    <span style={{ fontWeight: "light", fontSize: "1rem" }}>Seguro te preguntarás</span>
                    <br />
                    <span style={{ fontWeight: "bold", fontSize: "1.3rem" }}>¿Cómo comer según tus macronutrientes?</span>
                  </p>
                )}

                <ReactPlayer
                  height={isMobile ? "200px" : "300px"}
                  width={"100%"}
                  controls={true}
                  url={ChoosenUrl()}
                  playing={true}
                ></ReactPlayer>
                <p
                  className="text-center mt-4"
                  style={{
                    fontWeight: "bold",
                    marginTop: isMobile ? "" : "1.5rem",
                    fontSize: "1.3rem",
                    color: "#ef233c",
                  }}
                >
                  👇 Agenda tu sesión de asesoría gratuita 👇
                </p>
                <div
                  className="mt-2"
                  data-tf-live="01J3NCA0EMRVRMJFQ7JXH19Z98"
                  style={{
                    width: "100%",
                  }}
                ></div>
              </>
            )}
            <br />
          </div>
        </div>
      </div>
      {props.state.limitBudget === "2" && (
        <>
          <div className="text-center my-4">
            <p className="paragraph-result">
              <span>Analizamos tus respuestas y tenemos la mejor solución para puedas estar en forma.</span>
              <br />
              <br />
              <span>Se trata de nuestro programa</span>
              <span style={{ fontWeight: "bold" }}>{" FIT4CHALLENGE."}</span>
              <br />
              <br />
              <span>
                Haz clic en “EMPIEZA TU CAMBIO” y descubre cómo este programa te va a ayudar a eliminar de 6 a 10kg de grasa y ganar músculo
                los próximos 90 días.
              </span>
              <br />
              <br />
              <span>
                Por única vez solo a través de este botón👇, podrás adquirirlo no a USD 100, tampoco a USD 50, sino a tan sólo USD 17 al
                mes.
              </span>
            </p>
          </div>
          <div className="text-center my-2">
            <Tr4inerContainedButton
              variant="contained"
              size="large"
              sx={{
                textTransform: "uppercase",
              }}
              onClick={() => {
                // if (usuarioTipoURL() === TipoTest.TEST_MACROS) {
                //   OpenDefaultLink();
                // } else if (usuarioTipoURL() === TipoTest.TEST_VA_MACROS) {
                //   OpenDefaultLinkVA();
                // } else if (usuarioTipoURL() === TipoTest.TEST_VSLM_MACROS) {
                //   OpenDefaultLinkVSLMujer();
                // } else if (usuarioTipoURL() === TipoTest.TEST_VSLH_MACROS) {
                //   OpenDefaultLinkVSLHombre();
                // }
                window.open(
                  "https://store.tr4iner.com/fit4-challenge?utm_campaign=Fit4Challenge&utm_source=test-macros&utm_medium=Downsell",
                  "_blank"
                );
              }}
            >
              EMPIEZA TU CAMBIO
            </Tr4inerContainedButton>
          </div>
        </>
      )}
    </div>
  );
};

export default Resultados;
