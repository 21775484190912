import { IInitialState, AccionDetalle, TipoAccionDetalle } from "../Shared/data/FormReducer";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  Card,
  CardActionArea,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  RadioGroup,
  Select,
} from "@mui/material";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Radio from "@mui/material/Radio";
import { Tr4inerContainedButton, Tr4inerRadio, Tr4inerTextField } from "./Material";
import { es } from "date-fns/locale";
import ManIcon from "@mui/icons-material/Man";
import WomanIcon from "@mui/icons-material/Woman";
import { useEffect, useId, useState } from "react";

import { style } from "@mui/system";
import ToastDisplay from "../Shared/Utils/ToastDisplay";
import { toast } from "react-hot-toast";
import { handlerButtonToTop } from "../Shared/Utils/scroll";
import { registrarLeadAccion } from "../Services/Form";
import uuid from "react-uuid";

interface IForm1 {
  state: IInitialState;
  dispatch: React.Dispatch<AccionDetalle>;
}

const styles = {
  title: {
    fontWeight: 700,
  },
  bienvenida: {
    margin: "20px" as const,
  },
  textSexo: {
    fontWeight: 800,
  },
  selectCard: {
    border: 0,
    borderColor: "#ff8b01",
    borderWidth: "5px",
    opacity: 1,
    borderRadius: "70%",
    height: "140px",
    wordWrap: "break-word",
    boxShadow: "none",
  },
  unSelectCard: {
    border: 0,
    borderColor: "black",
    borderWidth: "1px",
    opacity: 0.4,
    borderRadius: "50%",
    height: "140px",
    wordWrap: "break-word",
    boxShadow: "none",
  },
};

const Form1: React.FC<IForm1> = (props) => {
  let errores = false;
  let errorEdad = false;

  const idSession = uuid();

  const today = new Date();
  const eighteenYearsAgo = new Date(today.setFullYear(today.getFullYear() - 18));
  // Restar un día a la fecha de 18 años atrás
  eighteenYearsAgo.setDate(eighteenYearsAgo.getDate() - 1);

  console.log(props);
  useEffect(() => {
    props.dispatch({
      type: TipoAccionDetalle.CHANGE_EDAD,
      payload: eighteenYearsAgo.toString(),
    });
  }, []);

  const handleSexo = (sexo: boolean) => {
    props.dispatch({
      type: TipoAccionDetalle.CHANGE_SEXO,
      payload: sexo,
    });
  };

  const diferenciaEdad = () => {
    let dateNow = new Date();
    let date = new Date(Date.parse(props.state.edad));

    let diferencia = dateNow.getFullYear() - date.getFullYear();
    return diferencia >= 18 ? true : false;
  };

  const handleNext = () => {
    if (props.state.estatura === "0" || props.state.estatura === "") {
      props.dispatch({
        type: TipoAccionDetalle.CHANGE_VALESTATURA,
        payload: true,
      });
      errores = true;
    }
    if (props.state.unidadEstatura === "pi") {
      if (props.state.pulgadas === "0" || props.state.pulgadas === "") {
        props.dispatch({
          type: TipoAccionDetalle.CHANGE_VALESTATURA,
          payload: true,
        });
        errores = true;
      }
    }
    if (!diferenciaEdad()) {
      errorEdad = true;
    }

    if (!errores && !errorEdad) {
      props.dispatch({
        type: TipoAccionDetalle.CHANGE_NFORM,
        payload: props.state.nForm + 1,
      });
      handlerButtonToTop();
      props.dispatch({
        type: TipoAccionDetalle.CHANGE_IDSESSION,
        payload: String(idSession),
      });
      registrarLeadAccion({
        accion: "siguiente1",
        lead: "testmacros-tr4iner",
        uid: idSession,
      });
    }
    if (errores) {
      errores = false;
      toast.error(<ToastDisplay title={``} message={"Completa tu altura antes de continuar."} />);
    }
    if (errorEdad) {
      errorEdad = false;
      toast.error(<ToastDisplay title={``} message={"Debes ser mayor de edad."} />);
    }
  };

  return (
    <div className="container form-macro">
      <div className="text-center">
        <h4 style={styles.title} className="mt-4">
          {/* <div className="text-capitalize">{props.state.nombre},</div> */}
          Es hora de conocer tus macros
        </h4>
      </div>
      <div className="text-center">
        <p style={styles.bienvenida}>
          Por favor responde este breve test para brindarte la cantidad exacta de macronutrientes que necesitas consumir.
        </p>
      </div>
      <section id="form1">
        <div className="row d-flex justify-content-center">
          <div className="col-12">
            <div className="text-center">
              <h5 className="title">¿Cuál es tu sexo?</h5>
              <div className="container mt-3">
                <div className="row choose-sex">
                  <div className="col-6 col-md-6 mb-3 mb-md-0">
                    <div className="d-flex justify-content-center">
                      <Card sx={props.state.sexo ? styles.selectCard : styles.unSelectCard}>
                        <CardActionArea onClick={() => handleSexo(true)}>
                          <CardContent>
                            <ManIcon sx={{ fontSize: "100px" }}></ManIcon>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </div>
                    <div style={styles.textSexo}>Hombre</div>
                  </div>
                  <div className="col-6 col-md-6 mb-3 mb-md-0">
                    <div className="d-flex justify-content-center">
                      <Card sx={!props.state.sexo ? styles.selectCard : styles.unSelectCard}>
                        <CardActionArea onClick={() => handleSexo(false)}>
                          <CardContent>
                            <WomanIcon sx={{ fontSize: "100px" }}></WomanIcon>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </div>
                    <div style={styles.textSexo}>Mujer</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row d-flex justify-content-center">
          <div className="col-12 col-md-6 text-center">
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={props.state.unidadEstatura}
                onChange={(e) => {
                  props.dispatch({
                    type: TipoAccionDetalle.CHANGE_UNIDADESTATURA,
                    payload: e.target.value,
                  });
                }}
              >
                <FormControlLabel
                  value="cm"
                  control={<Tr4inerRadio />}
                  label="Centímetros"
                  sx={{
                    color: props.state.themeColor === "light" ? "primary-color" : "dark-color",
                  }}
                />
                <FormControlLabel
                  value="pi"
                  control={<Tr4inerRadio />}
                  label="Pies"
                  sx={{
                    color: props.state.themeColor === "light" ? "primary-color" : "dark-color",
                  }}
                />
              </RadioGroup>
            </FormControl>
          </div>

          {props.state.unidadEstatura !== "" && (
            <div className={`${props.state.unidadEstatura !== "pi" ? "col-12 col-md-6" : "col-12 col-md-3"} text-center`}>
              <Tr4inerTextField
                error={props.state.valEstatura}
                fullWidth
                id="estatura"
                label={props.state.unidadEstatura !== "pi" ? "¿Cuánto mides?" : "Pies"}
                type="number"
                variant="standard"
                helperText={props.state.unidadEstatura !== "pi" && "Sin puntos ni comas decimales. Ej.: 175"}
                value={Number(props.state.estatura) === 0 ? "" : props.state.estatura}
                onChange={(e) => {
                  props.dispatch({
                    type: TipoAccionDetalle.CHANGE_VALESTATURA,
                    payload: false,
                  });
                  props.dispatch({
                    type: TipoAccionDetalle.CHANGE_ESTATURA,
                    payload: props.state.unidadEstatura === "pi" ? e.target.value : Number(e.target.value).toFixed(),
                  });
                }}
                inputProps={{ min: 0 }}
              />
            </div>
          )}
          {props.state.unidadEstatura === "pi" && (
            <div className="col-12 col-md-3 text-center">
              <Tr4inerTextField
                error={props.state.valEstatura}
                fullWidth
                type="number"
                label="Pulgadas"
                variant="standard"
                value={Number(props.state.pulgadas) === 0 ? "" : props.state.pulgadas}
                onChange={(e) => {
                  props.dispatch({
                    type: TipoAccionDetalle.CHANGE_VALESTATURA,
                    payload: false,
                  });
                  // setPulgadas(Number(e.target.value).toFixed())
                  props.dispatch({
                    type: TipoAccionDetalle.CHANGE_PULGADA,
                    payload: Number(e.target.value).toFixed(),
                  });
                }}
                inputProps={{ min: 0 }}
              />
            </div>
          )}
        </div>
        <br></br>

        <div className="row justify-content-center" style={{ margin: "8px 10px 0px 10px" }}>
          <div className="col-12 col-md-6 mb-3">
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
              <DesktopDatePicker
                label="Fecha de Nacimiento"
                views={["year", "month", "day"]}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <Tr4inerTextField
                    disabled
                    {...params}
                    style={{ width: "100%" }}
                    variant="standard"
                    sx={{
                      svg: {
                        color: props.state.themeColor === "light" ? "primary-color" : "dark-color",
                      },
                      input: {
                        color: props.state.themeColor === "light" ? "primary-color" : "dark-color",
                      },
                      label: {
                        color: props.state.themeColor === "light" ? "primary-color" : "dark-color",
                      },
                    }}
                  />
                )}
                value={eighteenYearsAgo}
                onChange={(e) => {
                  props.dispatch({
                    type: TipoAccionDetalle.CHANGE_EDAD,
                    payload: e !== null ? e.toString() : "",
                  });
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Tr4inerContainedButton
            variant="contained"
            size="large"
            sx={{
              textTransform: "uppercase",
            }}
            onClick={handleNext}
          >
            {`Siguiente >>`}
          </Tr4inerContainedButton>
        </div>
      </section>
    </div>
  );
};

export default Form1;
