import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
} from "@mui/material";
import {
  IInitialState,
  AccionDetalle,
  TipoAccionDetalle,
} from "../Shared/data/FormReducer";
import { TipoTest, usuarioTipoURL } from "../Shared/Utils/helper-tipo-usuario";

// ABTesting = 0
import perdergrasaHombre0 from "../Shared/Images/icono_grasa_hombre_2.png";
import musculacionHombre0 from "../Shared/Images/icono_musculo_hombre_2.png";
import perdergrasaMujer0 from "../Shared/Images/icono_grasa_mujer_2.png";
import musculacionMujer0 from "../Shared/Images/icono_musculo_mujer_2.png";

import perdergrasaHombreS0 from "../Shared/Images/icono_grasa_hombre_2_amarillo.png";
import musculacionHombreS0 from "../Shared/Images/icono_musculo_hombre_2_amarillo.png";
import perdergrasaMujerS0 from "../Shared/Images/icono_grasa_mujer_2_amarillo.png";
import musculacionMujerS0 from "../Shared/Images/icono_musculo_mujer_2_amarillo.png";

// ABTesting = 1
import perdergrasaHombre from "../Shared/Images/sobrepeso-hombre.jpg";
import fueraformaHombre from "../Shared/Images/fuera-de-forma-hombre.jpg";
import delgadograsoHombre from "../Shared/Images/grasa-rebelde-hombre.jpg";
import flacoHombre from "../Shared/Images/flaco-hombre.jpg";

import perdergrasaMujer from "../Shared/Images/sobrepeso-mujer.jpg";
import fueraformaMujer from "../Shared/Images/flaca-con-grasa-mujer.jpg";
import delgadograsoMujer from "../Shared/Images/promedio-mujer.jpg";
import flacaMujer from "../Shared/Images/flaca-mujer.jpg";

import {
  Tr4inerContainedButton,
  Tr4inerOutlinedButton,
  Tr4inerRadio,
} from "./Material";
import {
  GuardarUsuario,
  GuardarUsuarioVA,
  GuardarUsuarioVSL,
  registrarLeadAccion,
} from "../Services/Form";
import ToastDisplay from "../Shared/Utils/ToastDisplay";
import { toast } from "react-hot-toast";
import { handlerButtonToTop } from "../Shared/Utils/scroll";

interface IForm3 {
  state: IInitialState;
  dispatch: React.Dispatch<AccionDetalle>;
}

const Form3: React.FC<IForm3> = (props) => {
  /**(0) Muestra horizontalmente los objetivos que desea la persona, (1) Muestra verticalmente los posibles estados de la persona*/
  let ABTesting = 0;

  const handleNext = () => {
    let error1 = false;
    let error2 = false;
    if (props.state.tipoActividad === "") {
      props.dispatch({
        type: TipoAccionDetalle.CHANGE_VALTACTIVIDAD,
        payload: true,
      });
      error1 = true;
    }

    if (props.state.objetivo === "") {
      props.dispatch({
        type: TipoAccionDetalle.CHANGE_VALOBJETIVO,
        payload: true,
      });
      error2 = true;
    }

    if (!error1 && !error2) {
      props.dispatch({
        type: TipoAccionDetalle.CHANGE_NFORM,
        payload: props.state.nForm + 1,
      });
      handlerButtonToTop();
      registrarLeadAccion({
        accion: "siguiente3",
        lead: "testmacros-tr4iner",
        uid: props.state.idSession,
      });
      pasarAFinal();
    }
    if (error1 || error2) {
      if (error1 && error2) {
        toast.error(
          <ToastDisplay
            title={``}
            message={
              "Marca que tipo de actividad física tienes y tu objetivo a cumplir."
            }
          />
        );
      } else if (error1 && !error2) {
        toast.error(
          <ToastDisplay
            title={``}
            message={"Marca que tipo de actividad física tienes."}
          />
        );
      } else if (!error1 && error2) {
        toast.error(
          <ToastDisplay title={``} message={"Marca tu objetivo a cumplir."} />
        );
      }
      error1 = false;
      error2 = false;
    }
  };

  const pasarAFinal = () => {
    const date = new Date(Date.parse(props.state.edad));
    const FFormateada = `${date.getFullYear()}-${
      date.getMonth() + 1 < 10 ? "0" : ""
    }${date.getMonth() + 1}-${date.getDate() < 10 ? "0" : ""}${date.getDate()}`;

    var object = {
      Nombres: props.state.nombre,
      Correo: props.state.correo,
      Estatura:
        props.state.unidadEstatura !== "pi"
          ? props.state.estatura
          : props.state.estatura + "." + props.state.pulgadas,
      peso: props.state.peso,
      FechaNacimiento: FFormateada,
      PorcentajeGrasa: props.state.porcentajeGrasa,
      TipoActividad: props.state.tipoActividad,
      Objetivo: props.state.objetivo,
      UnidadPeso: props.state.unidadPeso,
      UnidadEstatura: props.state.unidadEstatura,
      Sexo: props.state.sexo,
      Refe: props.state.ref,
      UtmMedium: props.state.utm_medium,
      UtmSource: props.state.utm_source,
      UtmCampaign: props.state.utm_campaign,
      UtmTerm: props.state.utm_term,
      UtmContent: props.state.utm_content,
      Landing: props.state.landing,
      // ProfesionID: props.state.ocupation,
      // SituacionActual: props.state.actualSituation,
      // ImportanciaObjetivos: props.state.importanceObjectives,
      // PresupuestoAproximado: props.state.aproximateBudget,
      // LimitePresupuesto:
      //   props.state.aproximateBudget === "1" ? props.state.limitBudget : null,
    };

    if (usuarioTipoURL() === TipoTest.TEST_MACROS) {
      GuardarUsuario(object)
        .then((response) => {
          if (response.status === 200) {
            props.dispatch({
              type: TipoAccionDetalle.CHANGE_CARBOHIDRATOS,
              payload: response.data.data.carbohidratos,
            });
            props.dispatch({
              type: TipoAccionDetalle.CHANGE_PROTEINAS,
              payload: response.data.data.proteinas,
            });
            props.dispatch({
              type: TipoAccionDetalle.CHANGE_GRASAS,
              payload: response.data.data.grasas,
            });
            props.dispatch({
              type: TipoAccionDetalle.CHANGE_CALORIAS,
              payload: response.data.data.calorias,
            });
            props.dispatch({
              type: TipoAccionDetalle.CHANGE_NOMBRE,
              payload: response.data.data.nombres,
            });
          } else {
            console.log(
              "Ocurrió un problema, contáctese con nuestros asesores."
            );
            alert("Ocurrió un problema, contáctese con nuestros asesores.");
          }
        })
        .catch((err) => {
          console.log(err);
          alert(
            `Ocurrió un problema, contáctese con nuestros asesores. ${err}`
          );
        });
    } else if (usuarioTipoURL() === TipoTest.TEST_VA_MACROS) {
      GuardarUsuarioVA(object)
        .then((response) => {
          if (response.status === 200) {
            props.dispatch({
              type: TipoAccionDetalle.CHANGE_CARBOHIDRATOS,
              payload: response.data.data.carbohidratos,
            });
            props.dispatch({
              type: TipoAccionDetalle.CHANGE_PROTEINAS,
              payload: response.data.data.proteinas,
            });
            props.dispatch({
              type: TipoAccionDetalle.CHANGE_GRASAS,
              payload: response.data.data.grasas,
            });
            props.dispatch({
              type: TipoAccionDetalle.CHANGE_NOMBRE,
              payload: response.data.data.nombres,
            });
          } else {
            console.log(
              "Ocurrió un problema, contáctese con nuestros asesores."
            );
            alert("Ocurrió un problema, contáctese con nuestros asesores.");
          }
        })
        .catch((err) => {
          console.log(err);
          alert(
            `Ocurrió un problema, contáctese con nuestros asesores. ${err}`
          );
        });
    } else {
      GuardarUsuarioVSL(object)
        .then((response) => {
          if (response.status === 200) {
            props.dispatch({
              type: TipoAccionDetalle.CHANGE_CARBOHIDRATOS,
              payload: response.data.data.carbohidratos,
            });
            props.dispatch({
              type: TipoAccionDetalle.CHANGE_PROTEINAS,
              payload: response.data.data.proteinas,
            });
            props.dispatch({
              type: TipoAccionDetalle.CHANGE_GRASAS,
              payload: response.data.data.grasas,
            });
            props.dispatch({
              type: TipoAccionDetalle.CHANGE_NOMBRE,
              payload: response.data.data.nombres,
            });
          } else {
            console.log(
              "Ocurrió un problema, contáctese con nuestros asesores."
            );
            alert("Ocurrió un problema, contáctese con nuestros asesores.");
          }
        })
        .catch((err) => {
          console.log(err);
          alert(
            `Ocurrió un problema, contáctese con nuestros asesores. ${err}`
          );
        });
    }
  };

  return (
    <div className="mb-3">
      <div className="container form-macro mb-4">
        <section id="form3">
          <div>
            <div className="text-center">
              <h4 style={{ fontWeight: "700" }} className="mt-4">
                ¿Cuál es tu actividad física?
              </h4>
              <br />
            </div>
            <div className="row ms-5">
              <div className="col-12">
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="1"
                    name="radio-buttons-group"
                    value={props.state.tipoActividad}
                    onChange={(e) => {
                      props.dispatch({
                        type: TipoAccionDetalle.CHANGE_TIPOACTIVIDAD,
                        payload: e.target.value,
                      });
                    }}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: "1rem",
                      },
                    }}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Tr4inerRadio />}
                      sx={{
                        ".MuiFormControlLabel-label": {
                          fontWeight: 700,
                          fontSize: "1.25rem",
                        },
                      }}
                      label={
                        props.state.sexo === true ? "Sedentario" : "Sendetaria"
                      }
                    />
                    <div className="container ms-4 select-detail mb-2">
                      Paso la mayor parte del tiempo sentado. No realizo ningún
                      tipo de actividad física.
                    </div>
                    <FormControlLabel
                      value="2"
                      control={<Tr4inerRadio />}
                      sx={{
                        ".MuiFormControlLabel-label": {
                          fontWeight: 700,
                          fontSize: "1.25rem",
                        },
                      }}
                      label={
                        props.state.sexo === true
                          ? "Ligeramente activo"
                          : "Ligeramente activa"
                      }
                    />
                    <div className="container ms-4 select-detail mb-2">
                      Realizo actividad física de 1 a 3 veces por semana, ya sea
                      entrenar en casa o gym, trotar, montar bicicleta, jugar
                      fútbol, etc.
                    </div>

                    <FormControlLabel
                      value="3"
                      control={<Tr4inerRadio />}
                      sx={{
                        ".MuiFormControlLabel-label": {
                          fontWeight: 700,
                          fontSize: "1.25rem",
                        },
                      }}
                      label={props.state.sexo === true ? "Activo" : "Activa"}
                    />
                    <div className="container ms-4 select-detail">
                      Realizo actividad física de 3 a 6 veces por semana, ya sea
                      entrenar en casa o gym, trotar, montar bicicleta, jugar
                      fútbol, etc.
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="container form-macro">
        <section id="form4">
          <h4 className="text-center" style={{ fontWeight: "700" }}>
            {ABTesting === 0
              ? "¿Cuál es tu objetivo?"
              : "Selecciona la mejor descripción de tu estado actual"}
          </h4>
          {ABTesting === 0 && (
            <div
              className="row text-center"
              style={{ margin: "0px 10px 0px 10px" }}
            >
              <div className="col-6 col-md-6 mb-3 mb-md-0">
                <Card
                  sx={{
                    border: props.state.objetivo === "MM" ? 0 : 0,
                    opacity: props.state.objetivo === "MM" ? "1" : "0.4",
                    boxShadow: "none",
                  }}
                >
                  <CardActionArea
                    onClick={() =>
                      props.dispatch({
                        type: TipoAccionDetalle.CHANGE_OBJETIVO,
                        payload: "MM",
                      })
                    }
                  >
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={
                            props.state.sexo
                              ? props.state.objetivo === "MM"
                                ? musculacionHombreS0
                                : musculacionHombre0
                              : props.state.objetivo === "MM"
                              ? musculacionMujerS0
                              : musculacionMujer0
                          }
                          alt="ImagenMusculacion"
                          className="imgSize"
                        />
                      </div>
                    </CardContent>
                  </CardActionArea>
                </Card>
                <h6 className="m-0" style={{ fontWeight: "700" }}>
                  Masa Muscular
                </h6>
              </div>
              <div className="col-6 col-md-6 mb-3 mb-md-0">
                <Card
                  sx={{
                    border: props.state.objetivo === "PG" ? 0 : 0,
                    opacity: props.state.objetivo === "PG" ? "1" : "0.4",
                    boxShadow: "none",
                  }}
                >
                  <CardActionArea
                    onClick={() =>
                      props.dispatch({
                        type: TipoAccionDetalle.CHANGE_OBJETIVO,
                        payload: "PG",
                      })
                    }
                  >
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={
                            props.state.sexo
                              ? props.state.objetivo === "PG"
                                ? perdergrasaHombreS0
                                : perdergrasaHombre0
                              : props.state.objetivo === "PG"
                              ? perdergrasaMujerS0
                              : perdergrasaMujer0
                          }
                          className="imgSize"
                          alt="ImagenPerderGrasa"
                        />
                      </div>
                    </CardContent>
                  </CardActionArea>
                </Card>
                <h6 className="m-0" style={{ fontWeight: "700" }}>
                  Perder Grasa
                </h6>
              </div>
            </div>
          )}
          {ABTesting === 1 && (
            <div className="col-12">
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  value={props.state.objetivo}
                  onChange={(e) => {
                    props.dispatch({
                      type: TipoAccionDetalle.CHANGE_OBJETIVO,
                      payload: e.target.value,
                    });
                  }}
                >
                  <div className="row d-flex align-items-center">
                    <div className="col-3 text-center">
                      <img
                        src={
                          props.state.sexo
                            ? perdergrasaHombre
                            : perdergrasaMujer
                        }
                        alt="Imagen-Perdida-Grasa-Hombre"
                        className="img-fluid"
                        style={{ maxHeight: "150px" }}
                      />
                    </div>
                    <div className="col-6 col-9 p-0">
                      <FormControlLabel
                        value="PG"
                        control={<Tr4inerRadio />}
                        sx={{
                          ".MuiFormControlLabel-label": {
                            fontWeight: 700,
                            fontSize: "1.5rem",
                          },
                        }}
                        label="Tengo sobrepeso"
                      />
                      <div className="container ms-4 select-detail">
                        Deseo Mejorar mi salud. Mi objetivo principal es bajar
                        más de 15 kg.
                      </div>
                    </div>
                  </div>

                  <br />
                  <div className="row d-flex align-items-center">
                    <div className="col-3 text-center">
                      <img
                        src={
                          props.state.sexo ? fueraformaHombre : fueraformaMujer
                        }
                        alt="Imagen-Perdida-Grasa-Hombre"
                        className="img-fluid"
                        style={{ maxHeight: "150px" }}
                      />
                    </div>
                    <div className="col-6 col-9 p-0">
                      <FormControlLabel
                        value="FM"
                        control={<Tr4inerRadio />}
                        sx={{
                          ".MuiFormControlLabel-label": {
                            fontWeight: 700,
                            fontSize: "1.5rem",
                          },
                        }}
                        label="Estoy fuera de forma"
                      />
                      <div className="container ms-4 select-detail">
                        {props.state.sexo === true
                          ? "Necesito bajar de 7 a 15 kg y lucir muy atlético y definido."
                          : "Necesito bajar de 7 a 15 kg y lucir más esbelta y tonificada."}
                      </div>
                    </div>
                  </div>
                  <br />

                  <div className="row d-flex align-items-center">
                    <div className="col-3 text-center">
                      <img
                        src={
                          props.state.sexo
                            ? delgadograsoHombre
                            : delgadograsoMujer
                        }
                        alt="Imagen-Perdida-Grasa-Hombre"
                        className="img-fluid"
                        style={{ maxHeight: "150px" }}
                      />
                    </div>
                    <div className="col-6 col-9 p-0">
                      <FormControlLabel
                        value="DG"
                        control={<Tr4inerRadio />}
                        sx={{
                          ".MuiFormControlLabel-label": {
                            fontWeight: 700,
                            fontSize: "1.5rem",
                          },
                        }}
                        label={
                          props.state.sexo === true
                            ? "Delgado con grasa rebelde"
                            : "Delgada con grasa rebelde"
                        }
                      />
                      <div className="container ms-4 select-detail">
                        {props.state.sexo === true
                          ? "Deseo eliminar mi grasa abdominal y aumentar masa muscular."
                          : "Deseo eliminar mi grasa abdominal y aumentar piernas y glúteos."}
                      </div>
                    </div>
                  </div>
                  <br />

                  <div className="row d-flex align-items-center">
                    <div className="col-3 text-center">
                      <img
                        src={props.state.sexo ? flacoHombre : flacaMujer}
                        alt="Imagen-Perdida-Grasa-Hombre"
                        className="img-fluid"
                        style={{ maxHeight: "150px" }}
                      />
                    </div>
                    <div className="col-6 col-9 p-0">
                      <FormControlLabel
                        value="MM"
                        control={<Tr4inerRadio />}
                        sx={{
                          ".MuiFormControlLabel-label": {
                            fontWeight: 700,
                            fontSize: "1.5rem",
                          },
                        }}
                        label={
                          props.state.sexo === true
                            ? "Soy muy flaco"
                            : "Soy muy delgada"
                        }
                      />
                      <div className="container ms-4 select-detail">
                        {props.state.sexo === true
                          ? "Quiero desarrollar los músculos de todo mi cuerpo de forma proporcionada y lograr un adbomen definido"
                          : "Quiero desarrollar los músculos de mis piernas, glúteos y de todo mi cuerpo de forma proporcionada."}
                      </div>
                    </div>
                  </div>
                </RadioGroup>
              </FormControl>
            </div>
          )}
          <br />
        </section>
      </div>
      <div className="row d-flex">
        <div className="col-6 d-flex justify-content-end">
          <Tr4inerOutlinedButton
            variant="outlined"
            size="large"
            sx={{
              color:
                props.state.themeColor === "light"
                  ? "primary-color"
                  : "dark-color",
              textTransform: "uppercase",
              width: "130px",
            }}
            onClick={() => {
              props.dispatch({
                type: TipoAccionDetalle.CHANGE_NFORM,
                payload: props.state.nForm - 1,
              });
              handlerButtonToTop();
            }}
          >
            {`<< Atrás`}
          </Tr4inerOutlinedButton>
        </div>
        <div className="col-6 d-flex justify-content-start">
          <Tr4inerContainedButton
            variant="contained"
            size="large"
            sx={{
              textTransform: "uppercase",
              mr: 3,
            }}
            onClick={handleNext}
          >
            {`Siguiente >>`}
          </Tr4inerContainedButton>
        </div>
      </div>
      <br />
    </div>
  );
};

export default Form3;
