import axios from "axios";
import { baseUrlProduction } from "../Config/baseUrl";
import { IRegistrarLeadAccion } from "../Interfaces/Form";

const baseURL = baseUrlProduction;

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const GuardarUsuario = (data: any) => {
  return axios.post(baseURL + "usuario/RegistrarMacroCuestionarioTest", data, config);
};

export const GuardarUsuarioVA = (data: any) => {
  return axios.post(baseURL + "usuario/RegistrarMacroCuestionarioTestVA", data, config);
};

export const GuardarUsuarioVSL = (data: any) => {
  return axios.post(baseURL + "usuario/RegistrarMacroCuestionarioTestVSL", data, config);
};

export const obtenerProfesiones = () => {
  return axios.get(baseURL + "configuracion/listarProfesiones", config);
};

export const registrarLeadAccion = (data: IRegistrarLeadAccion) => {
  return axios.post(baseURL + "usuario/registarLeadAccion", data, config);
};
