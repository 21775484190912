/**
 * En este archivo se encuentran las estructuras necesarias para manejar el state de implemento seleccionado
 * cuando se da click a "Mas detalles"
 */

export enum TipoAccionDetalle {
  CHANGE_NOMBRE = "CHANGE_NOMBRE",
  CHANGE_CORREO = "CHANGE_CORREO",
  CHANGE_ESTATURA = "CHANGE_ESTATURA",
  CHANGE_EDAD = "CHANGE_EDAD",
  CHANGE_PESO = "CHANGE_PESO",
  CHANGE_PORCENTAJEGRASA = "CHANGE_PORCENTAJEGRASA",
  CHANGE_TIPOACTIVIDAD = "CHANGE_TIPOACTIVIDAD",
  CHANGE_OBJETIVO = "CHANGE_OBJETIVO",
  CHANGE_UNIDADPESO = "CHANGE_UNIDADPESO",
  CHANGE_UNIDADESTATURA = "CHANGE_UNIDADESTATURA",
  CHANGE_SEXO = "CHANGE_SEXO",
  CHANGE_NFORM = "CHANGE_NFORM",
  CHANGE_RESET = "RESET",
  CHANGE_PROTEINAS = "CHANGE_PROTEINAS",
  CHANGE_CARBOHIDRATOS = "CHANGE_CARBOHIDRATOS",
  CHANGE_GRASAS = "CHANGE_GRASAS",
  CHANGE_CALORIAS = "CHANGE_CALORIAS",
  CHANGE_VALESTATURA = "CHANGE_VALESTATURA",
  CHANGE_VALPESO = "CHANGE_VALPESO",
  CHANGE_VALTACTIVIDAD = "CHANGE_VALTACTIVIDAD",
  CHANGE_VALOBJETIVO = "CHANGE_VALOBJETIVO",
  CHANGE_VALPGRASA = "CHANGE_VALPGRASA",
  CHANGE_VALNOMBRE = "CHANGE_VALNOMBRE",
  CHANGE_VALCORREO = "CHANGE_VALCORREO",
  CHANGE_VALCAPTCHA = "CHANGE_VALCAPTCHA",
  CHANGE_PULGADA = "CHANGE_PULGADA",
  CHANGE_THEMECOLOR = "CHANGE_THEMECOLOR",
  CHANGE_REF = "CHANGE_REF",
  CHANGE_UTM_MEDIUM = "CHANGE_UTM_MEDIUM",
  CHANGE_UTM_SOURCE = "CHANGE_UTM_SOURCE",
  CHANGE_UTM_CAMPAIGN = "CHANGE_UTM_CAMPAIGN",
  CHANGE_UTM_TERM = "CHANGE_UTM_TERM",
  CHANGE_UTM_CONTENT = "CHANGE_UTM_CONTENT",
  CHANGE_LANDING = "CHANGE_LANDING",
  CHANGE_OCUPATION = "CHANGE_OCUPATION",
  CHANGE_ACTUALSITUATION = "CHANGE_ACTUALSITUATION",
  CHANGE_IMPORTANCEOBJECTIVES = "CHANGE_IMPORTANCEOBJECTIVES",
  CHANGE_APROXIMATEBUDGET = "CHANGE_APROXIMATEBUDGET",
  CHANGE_LIMITBUDGET = "CHANGE_LIMITBUDGET",
  CHANGE_IDSESSION = "CHANGE_IDSESSION",
}

export interface AccionDetalle {
  type: TipoAccionDetalle;
  payload: string | number | boolean | number[];
}

export interface IInitialState {
  nombre: string;
  correo: string;
  estatura: string;
  pulgadas: string;
  edad: string;
  peso: number;
  porcentajeGrasa: number;
  tipoActividad: string;
  objetivo: string;
  unidadPeso: string;
  unidadEstatura: string;
  sexo: boolean;
  nForm: number;
  proteinas: number;
  carbohidratos: number;
  grasas: number;
  calorias: number;
  valEstatura: boolean;
  valPeso: boolean;
  valTActividad: boolean;
  valObjetivo: boolean;
  valPGrasa: boolean;
  valNombre: boolean;
  valCorreo: boolean;
  valCaptcha: boolean;
  valUPeso: boolean;
  themeColor: string;
  ref: string;
  utm_medium: string;
  utm_source: string;
  utm_campaign: string;
  utm_term: string;
  utm_content: string;
  landing: string;
  ocupation: string;
  actualSituation: number[];
  importanceObjectives: string;
  aproximateBudget: string;
  limitBudget: string;
  idSession: string;
}

export const initialState: IInitialState = {
  nombre: "",
  correo: "",
  estatura: "0",
  edad: new Date().toString(),
  peso: 0,
  porcentajeGrasa: 0,
  tipoActividad: "",
  pulgadas: "0",
  objetivo: "",
  unidadPeso: "",
  unidadEstatura: "",
  sexo: true,
  nForm: 1,
  proteinas: 0,
  carbohidratos: 0,
  grasas: 0,
  calorias: 0,
  valEstatura: false,
  valPeso: false,
  valTActividad: false,
  valObjetivo: false,
  valPGrasa: false,
  valNombre: false,
  valCorreo: false,
  valCaptcha: false,
  valUPeso: false,
  themeColor: "light",
  ref: "",
  utm_medium: "",
  utm_source: "",
  utm_campaign: "",
  utm_term: "",
  utm_content: "",
  landing: "",
  ocupation: "",
  actualSituation: [],
  importanceObjectives: "",
  aproximateBudget: "",
  limitBudget: "",
  idSession: "",
};

export const detalleReducer = (state: IInitialState, action: AccionDetalle) => {
  const { type, payload } = action;
  switch (type) {
    case TipoAccionDetalle.CHANGE_NOMBRE:
      return {
        ...state,
        nombre: String(payload),
      };

    case TipoAccionDetalle.CHANGE_CORREO:
      return {
        ...state,
        correo: String(payload),
      };
    case TipoAccionDetalle.CHANGE_ESTATURA:
      return {
        ...state,
        estatura: String(payload),
      };
    case TipoAccionDetalle.CHANGE_PULGADA:
      return {
        ...state,
        pulgadas: String(payload),
      };
    case TipoAccionDetalle.CHANGE_EDAD:
      return {
        ...state,
        edad: String(payload),
      };
    case TipoAccionDetalle.CHANGE_PESO:
      return {
        ...state,
        peso: Number(payload),
      };
    case TipoAccionDetalle.CHANGE_PORCENTAJEGRASA:
      return {
        ...state,
        porcentajeGrasa: Number(payload),
      };
    case TipoAccionDetalle.CHANGE_TIPOACTIVIDAD:
      return {
        ...state,
        tipoActividad: String(payload),
      };
    case TipoAccionDetalle.CHANGE_OBJETIVO:
      return {
        ...state,
        objetivo: String(payload),
      };
    case TipoAccionDetalle.CHANGE_UNIDADPESO:
      return {
        ...state,
        unidadPeso: String(payload),
      };
    case TipoAccionDetalle.CHANGE_UNIDADESTATURA:
      return {
        ...state,
        unidadEstatura: String(payload),
      };
    case TipoAccionDetalle.CHANGE_SEXO:
      return {
        ...state,
        sexo: Boolean(payload),
      };
    case TipoAccionDetalle.CHANGE_NFORM:
      return {
        ...state,
        nForm: Number(payload),
      };
    case TipoAccionDetalle.CHANGE_CARBOHIDRATOS:
      return {
        ...state,
        carbohidratos: Number(payload),
      };
    case TipoAccionDetalle.CHANGE_PROTEINAS:
      return {
        ...state,
        proteinas: Number(payload),
      };
    case TipoAccionDetalle.CHANGE_GRASAS:
      return {
        ...state,
        grasas: Number(payload),
      };
    case TipoAccionDetalle.CHANGE_CALORIAS:
      return {
        ...state,
        calorias: Number(payload),
      };
    case TipoAccionDetalle.CHANGE_VALESTATURA:
      return {
        ...state,
        valEstatura: Boolean(payload),
      };
    case TipoAccionDetalle.CHANGE_VALPESO:
      return {
        ...state,
        valPeso: Boolean(payload),
      };
    case TipoAccionDetalle.CHANGE_VALTACTIVIDAD:
      return {
        ...state,
        valTActividad: Boolean(payload),
      };
    case TipoAccionDetalle.CHANGE_VALOBJETIVO:
      return {
        ...state,
        valObjetivo: Boolean(payload),
      };
    case TipoAccionDetalle.CHANGE_VALPGRASA:
      return {
        ...state,
        valPGrasa: Boolean(payload),
      };
    case TipoAccionDetalle.CHANGE_VALNOMBRE:
      return {
        ...state,
        valNombre: Boolean(payload),
      };
    case TipoAccionDetalle.CHANGE_VALCORREO:
      return {
        ...state,
        valCorreo: Boolean(payload),
      };
    case TipoAccionDetalle.CHANGE_VALCAPTCHA:
      return {
        ...state,
        valCaptcha: Boolean(payload),
      };
    case TipoAccionDetalle.CHANGE_THEMECOLOR:
      return {
        ...state,
        themeColor: String(payload),
      };
    case TipoAccionDetalle.CHANGE_REF:
      return {
        ...state,
        ref: String(payload),
      };

    case TipoAccionDetalle.CHANGE_UTM_MEDIUM:
      return {
        ...state,
        utm_medium: String(payload),
      };

    case TipoAccionDetalle.CHANGE_UTM_SOURCE:
      return {
        ...state,
        utm_source: String(payload),
      };

    case TipoAccionDetalle.CHANGE_UTM_CAMPAIGN:
      return {
        ...state,
        utm_campaign: String(payload),
      };

    case TipoAccionDetalle.CHANGE_UTM_TERM:
      return {
        ...state,
        utm_term: String(payload),
      };

    case TipoAccionDetalle.CHANGE_UTM_CONTENT:
      return {
        ...state,
        utm_content: String(payload),
      };

    case TipoAccionDetalle.CHANGE_LANDING:
      return {
        ...state,
        landing: String(payload),
      };

    case TipoAccionDetalle.CHANGE_OCUPATION:
      return {
        ...state,
        ocupation: String(payload),
      };

    case TipoAccionDetalle.CHANGE_ACTUALSITUATION:
      return {
        ...state,
        actualSituation: payload as number[],
      };

    case TipoAccionDetalle.CHANGE_IMPORTANCEOBJECTIVES:
      return {
        ...state,
        importanceObjectives: String(payload),
      };

    case TipoAccionDetalle.CHANGE_APROXIMATEBUDGET:
      return {
        ...state,
        aproximateBudget: String(payload),
      };

    case TipoAccionDetalle.CHANGE_LIMITBUDGET:
      return {
        ...state,
        limitBudget: String(payload),
      };

    case TipoAccionDetalle.CHANGE_IDSESSION:
      return {
        ...state,
        idSession: String(payload),
      };

    case TipoAccionDetalle.CHANGE_RESET:
      return initialState;
  }
};
