import { useReducer } from "react";
import { initialState, detalleReducer } from "./Shared/data/FormReducer";

import Header from "./Components/Header";
import Steps from "./Components/Steps";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainForm from "./Components/MainForm";
import Footer from "./Components/Footer";

function App() {
  const [state, dispatch] = useReducer(detalleReducer, initialState);

  return (
    <div
      className={`App ${
        state.themeColor === "light" ? "theme-light" : "theme-dark"
      }`}
    >
      <div className="page-container">
        <div className="content-wrap bg">
          <BrowserRouter>
            <Header />
            {state.nForm > 0 && state.nForm < 4 && (
              <Steps state={state} dispatch={dispatch}  />
            )}
            
            <Routes>
              <Route
                path="/"
                element={<MainForm state={state} dispatch={dispatch} />}
              />
            </Routes>
          </BrowserRouter>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
